<template>
  <app-collapse>
    <app-collapse-item
      class="my-1"
      :title="file.file_category"
      @visible="value => loadImg(value, file.id)"
    >
      <template #header>
        <h3>{{ file.file_category }}
          <b-badge
            v-for="(item, key) in (promo.promo.file_types && promo.promo.file_types.hasOwnProperty(file.file_category) ? promo.promo.file_types[file.file_category] : [])"
            :key="key"
            class="ml-1"
            variant="light-primary"
          >
            {{ key.toUpperCase() }}: {{ item }}
          </b-badge>
        </h3>
        <b-badge
          size="xl"
          class="float-right"
          :variant="`light-${FILE_STATES[file.state].variant}`"
        >
          {{ FILE_STATES[file.state].title }}
        </b-badge>
      </template>

      <div class="d-flex">
        <div
          class="d-flex flex-wrap"
        >
          <label class="font-small-3 font-weight-semibold">Image:</label>
          <b-overlay :show="imgLoading">
            <b-img
              :src="img"
              :alt="file.file_category"
              width="300"
              @click="index = 0"
            />
          </b-overlay>
        </div>
        <div class="ml-md-2 w-100 text-md-left">
          <b-form-group
            v-for="(item, key, index) in file.content"
            :key="index"
          >
            <label
              class="font-small-3 font-weight-semibold"
            >
              {{ key.toUpperCase() }}
            </label>
            <b-form-textarea
              v-if="item.length > 128"
              :value="item"
              readonly
            />
            <b-form-input
              v-else
              :value="item"
              readonly
            />
          </b-form-group>
          <b-overlay :show="patchLoading">
            <b-button
              variant="outline-danger"
              class="m-1"
              @click.prevent="onChangeState('declined')"
            >
              Decline
            </b-button>
            <b-button
              variant="outline-primary"
              class="m-1"
              @click.prevent="onChangeState('uploaded')"
            >
              Set as uploaded
            </b-button>
            <b-button
              variant="outline-success"
              class="m-1"
              @click.prevent="onChangeState('completed')"
            >
              Completed
            </b-button>
          </b-overlay>
        </div>
      </div>
      <vue-gallery
        v-if="img"
        :id="`${file.file_category}-gallery`"
        :images="[img]"
        :index="index"
        @close="index = null"
      />
    </app-collapse-item>
  </app-collapse>
</template>
<script>

import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BFormTextarea, BImg, BOverlay, BButton, BBadge, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import VueGallery from 'vue-gallery'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch } from 'vuex-pathify'
import PromoApi from '@/api/promo'
import { FILE_STATES } from '@/enums/promo'

export default {
  components: {
    VueGallery,
    AppCollapse,
    BFormTextarea,
    BImg,
    BOverlay,
    AppCollapseItem,
    BButton,
    BBadge,
    BFormGroup,
    BFormInput,
  },
  props: {
    fileData: Object,
    promo: Object,
  },
  data() {
    return {
      img: null,
      index: null,
      imgLoading: false,
      patchLoading: false,
      FILE_STATES,
      file: null,
    }
  },
  beforeMount() {
    this.file = { ...this.fileData }
  },
  methods: {
    loadImg(visible = true) {
      if (!visible) return
      if (this.file.id === null || this.img) return

      this.imgLoading = true
      PromoApi.getPromoFile(this.file.id).then(response => {
        this.img = 'data:image/jpg;base64,'.concat(response)
      }).finally(() => {
        this.imgLoading = false
      })
    },
    onChangeState(state) {
      this.patchLoading = true
      PromoApi.patchPromoFile(this.file.id, { state }).then(response => {
        this.file = response
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File was updated.',
            icon: 'WarningIcon',
            variant: 'success',
          },
        })
        dispatch('promoToUserList/get', this.promo.id)
      })
        .finally(() => {
          this.patchLoading = false
        })
    },
  },
}
</script>
