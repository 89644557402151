<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="4"
          class="mb-2"
        >
          <h3>Name or email:</h3>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                placeholder="Search by name or email"
                @keyup.enter="defaultFilter.search = search"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="clearSearch"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  :disabled="!search"
                  @click="defaultFilter.search = search"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="2"
          class="mb-2"
        >
          <h3>States:</h3>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="defaultFilter.state"
            name="flavour-1"
            class="d-flex flex-wrap"
          >
            <b-badge
              v-for="(value, key, index) in PROMO_STATES"
              :key="index"
              :variant="`light-${value.variant}`"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                :value="key"
                class="vh-center"
              >
                <span :class="`text-${value.variant}`">{{ value.title }}</span>
              </b-form-checkbox>
            </b-badge>
          </b-form-checkbox-group>
        </b-col>
        <b-col
          lg="2"
          class="mb-2"
        >
          <h3>User states:</h3>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="defaultFilter.user_state"
            name="flavour-1"
            class="d-flex flex-wrap"
          >
            <b-badge
              v-for="(value, key, index) in PROMO_STATES"
              :key="index"
              :variant="`light-${value.variant}`"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                :value="key"
                class="vh-center"
              >
                <span :class="`text-${value.variant}`">{{ value.title }}</span>
              </b-form-checkbox>
            </b-badge>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card>
    <data-table
      :module-name="'promoToUserList'"
      :fields="fields"
      :filters="defaultFilter"
      sort-by="updatedAt"
      :sort-desc="true"
    >
      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(user_state)="data">
        <b-badge
          :variant="`light-${PROMO_STATES[data.item.user_state].variant}`"
        >
          {{ PROMO_STATES[data.item.user_state].title }}
        </b-badge>
      </template>

      <template #cell(state)="data">
        <b-badge
          :variant="`light-${PROMO_STATES[data.item.state].variant}`"
        >
          {{ PROMO_STATES[data.item.state].title }}
        </b-badge>
      </template>

      <template #cell(updatedAt)="data">
        {{ formatDateTime(data.item.updatedAt) }}
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(action)="data">
        <b-button
          variant="outline-primary"
          @click.prevent="onShowFilesModal(data.item)"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
      </template>
    </data-table>
    <b-modal
      id="fileModal"
      title="Users file detail"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
      @close="onCloseFilesDetail"
    >
      <promo-files-preview
        :promo-to-user-id="promoToUserId"
        @save="onCloseFilesDetail"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol, BFormCheckbox, BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink, BModal,
  BRow,
} from 'bootstrap-vue'
import { dispatch } from 'vuex-pathify'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'
import DataTable from '@/components/ui/tables/DataTable.vue'
import { formatDateTime } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import PromoFilesPreview from '@/views/promo/detail/components/PromoFilesPreview.vue'
import { PROMO_STATES } from '@/enums/promo'

const fields = [
  {
    key: 'user',
    sortable: false,
  },
  {
    key: 'user_state',
    sortable: true,
  },
  {
    key: 'state',
    sortable: true,
  },
  {
    key: 'updatedAt',
    sortable: true,
  },
  {
    key: 'createdAt',
    sortable: true,
  },
  {
    key: 'action',
    sortable: false,
  },
]

export default {
  components: {
    BFormCheckboxGroup,
    BFormCheckbox,
    PromoFilesPreview,
    BModal,
    BLink,
    DataTable,
    BCard,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BFormGroup,
    BBadge,
    BFormInput,
    TagsList,
    BRow,
    RiskScoreBadge,
  },
  props: {
    defaultFilter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fields,
      advancedFilter: {
        search: '',
        user_state: ['completed'],
        state: ['started'],
      },
      search: '',
      promoToUserId: null,
      PROMO_STATES,
    }
  },
  beforeMount() {
    if (!this.defaultFilter) this.defaultFilter = { ...this.advancedFilter }
  },
  methods: {
    formatDateTime,
    getUserExtraTags,
    clearSearch() {
      this.defaultFilter.search = ''
      this.search = ''
    },
    onShowFilesModal(item) {
      this.promoToUserId = item.id
      dispatch('promoToUserList/get', item.id).then(() => {
        this.$bvModal.show('fileModal')
      })
    },
    onCloseFilesDetail() {
      this.$bvModal.hide('fileModal')
    },
  },
}
</script>
