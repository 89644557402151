const PROMO_STATES = {
  started: {
    name: 'started',
    title: 'Started',
    variant: 'primary',
  },
  completed: {
    name: 'completed',
    title: 'Completed',
    variant: 'success',
  },
  declined: {
    name: 'declined',
    title: 'Declined',
    variant: 'danger',
  },
}

const FILE_STATES = {
  uploaded: {
    name: 'uploaded',
    title: 'Uploaded',
    variant: 'primary',
  },
  completed: {
    name: 'completed',
    title: 'Completed',
    variant: 'success',
  },
  declined: {
    name: 'declined',
    title: 'Declined',
    variant: 'danger',
  },
}

export { PROMO_STATES, FILE_STATES }
