<template>
  <promo-to-user-table :default-filter="advancedFilter" />
</template>
<script>
import PromoToUserTable from '@/views/promo/detail/components/PromoToUserTable.vue'

export default {
  components: { PromoToUserTable },
  data() {
    return {
      advancedFilter: {
        search: '',
        promo: null,
        user_state: ['completed'],
        state: ['started'],
      },
    }
  },
  computed: {
    promoId() {
      return this.$route.params.id
    },
  },
  beforeMount() {
    this.advancedFilter.promo = this.promoId
  },
}
</script>
