<template>
  <b-overlay :show="loading">
    <b-card-header>
      <b-card-title>
        {{ promo.promo.name }} -
        <b-link :to="{ name: 'user-detail', params: { id: promo.user.id } }">
          <small>{{ promo.user.email }}</small>
        </b-link>
        <b-badge
          class="float-right"
          size="xl"
          :variant="`light-${PROMO_STATES[promo.user_state].variant}`"
        >
          {{ PROMO_STATES[promo.user_state].title }}
        </b-badge>

      </b-card-title>
    </b-card-header>
    <b-list-group>
      <b-list-group-item
        v-for="(item, key) in rewardTable"
        :key="key"
      >
        <strong>{{ item.points }}:</strong> {{ item.reward }}
      </b-list-group-item>
    </b-list-group>
    <hr>
    <b-card>
      Files
      <file-view
        v-for="(file, key) in files"
        :key="key"
        :file-data="file"
        :promo="promo"
      />

      <label>Comment</label>
      <b-form-textarea
        v-model="promo.comment"
        rows="8"
      />
      <b-card-footer>
        <b-form-group
          description="State"
          content-cols="2"
        >
          <b-form-select
            v-model="promo.state"
            :options="stateOptions"
          />
        </b-form-group>
        <b-button
          class="float-right"
          variant="outline-success"
          @click.prevent="onSave"
        >
          Save
        </b-button>
        <b-button
          variant="outline-primary"
          class="btn-icon float-right mb-auto mr-2"
          @click="onCreateAccount"
        >
          <feather-icon icon="PlusIcon" /> Create account
        </b-button>
      </b-card-footer>
    </b-card>
    <b-modal
      id="createModal"
      title="Create Account"
      :no-close-on-backdrop="true"
      size="xl"
      hide-footer
    >
      <new-account
        :user="promo.user"
        :auto-redirect="false"
        @completed="onCloseAccountModal"
      />
    </b-modal>
  </b-overlay>
</template>
<script>
import {
  BOverlay,
  BCard,
  BFormTextarea,
  BCardFooter,
  BButton,
  BFormSelect,
  BFormGroup,
  BCardTitle,
  BLink,
  BBadge,
  BCardHeader,
  BModal,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { dispatch, get } from 'vuex-pathify'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FileView from '@/views/promo/detail/components/FileView.vue'
import { PROMO_STATES } from '@/enums/promo'
import NewAccount from '@/views/account/new/NewAccount.vue'

export default {
  components: {
    NewAccount,
    BModal,
    BLink,
    BFormTextarea,
    FileView,
    BCard,
    BOverlay,
    BButton,
    BCardFooter,
    BFormSelect,
    BFormGroup,
    BCardTitle,
    BBadge,
    BCardHeader,
    BListGroup,
    BListGroupItem,
  },
  props: {
    promoToUserId: String,
  },
  data() {
    return {
      loading: false,
      images: [],
      testFile: null,
      stateOptions: Object.keys(PROMO_STATES),
      PROMO_STATES,
      rewardTable: [
        {
          points: 4,
          reward: '25K Track account',
          shortReward: '25K Track',
        },
        {
          points: 5,
          reward: '50K Track account',
          shortReward: '50K Track',
        },
        {
          points: 6,
          reward: '100K Track account',
          shortReward: '100K Track',
        },
        {
          points: 7,
          reward: '200K Track account',
          shortReward: '200K Track',
        },
      ],
    }
  },
  computed: {
    ...get('promoToUserList@row', {
      promo: 'promoToUser',
      files: 'files',
    }),
  },
  methods: {
    onSave() {
      dispatch('promoToUserList/update', { id: this.promo.id, data: this.promo })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Promo was updated.',
              icon: 'WarningIcon',
              variant: 'success',
            },
          })
          this.$emit('save')
        })
    },
    onCreateAccount() {
      this.$bvModal.show('createModal')
    },
    onCloseAccountModal() {
      this.$bvModal.hide('createModal')
    },
  },
}
</script>
