import axiosInstance from '@/plugins/axios'

export default {
  getPromoFile(fileId) {
    const url = `admin/promo/file/${fileId}`

    return axiosInstance.get(url, {
      responseType: 'arraybuffer',
    })
      .then(response => Buffer.from(response.data, 'binary').toString('base64'))
  },

  patchPromoFile(fileId, file) {
    const url = `admin/promo/file/${fileId}`

    return axiosInstance.patch(url, file)
      .then(response => response.data)
  },
}
